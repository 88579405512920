import React, { useState, MouseEvent } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IosShareIcon from "@mui/icons-material/IosShare";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { RenameSession, ShareSession, DeleteSession } from "./api";

interface SessionActionsProps {
  sessionId: string;
  onMenuClose: () => void;
  isOpen: boolean;
  onMenuOpen: (sessionId: string, event: MouseEvent<HTMLElement>) => void;
  onRename: () => void;
  onShare: () => void;
  refreshSessions: () => void;
}

const SessionActions: React.FC<SessionActionsProps> = ({
  sessionId,
  isOpen,
  onMenuOpen,
  onMenuClose,
  onRename,
  onShare,
  refreshSessions,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onMenuOpen(sessionId, event);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onMenuClose();
  };

  const handleShare = (action: string) => {
    handleClose();
    const userid = parseInt(prompt("Enter user id") || "");
    if (userid) {
      ShareSession(sessionId, userid);
    }
  };

  const handleDelete = () => {
    handleClose();
    DeleteSession(sessionId);
    refreshSessions();
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={isOpen ? anchorEl : null} // Open menu if isOpen is true
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={() => onRename()}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        {/* <MenuItem onClick={() => onShare()}>
          <ListItemIcon>
            <IosShareIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Share</ListItemText>
        </MenuItem> */}
        <MenuItem onClick={() => handleDelete()}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SessionActions;
