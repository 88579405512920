import React, { useContext, useState } from 'react'

import Avatar from '@mui/material/Avatar'
import CellContent from './CellContent'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import { Link } from 'react-router-dom'
import Star from '@mui/icons-material/Star'
import StarBorder from '@mui/icons-material/StarBorder'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { WebsocketContext } from 'components/context/WebsocketContext'
import { actions } from 'store'
import { connect } from 'react-redux'
import yellow from '@mui/material/colors/yellow'
import { Tooltip } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HealthCheckEmailSubscriptionCheckbox from './HealthCheckEmailSubscriptionCheckbox'

const yellowA700 = yellow['A700']

const RenderCell = ({ column: { name, hidden }, selectedFilter, row, value, favoriteAction, CelltoogleRowDetailModal, className, style, extraCols, extraRender }) => {
  const [favoris, setFavoris] = useState({})
  const { handleDownload } = useContext(WebsocketContext)
  if (hidden) return null;

  if (extraCols && extraCols.map(col => col.col.name).filter(col => col === name).length && extraRender) {
    return (<Table.Cell className={className} style={style}>
      {extraRender(name, row)}
    </Table.Cell>);
  }

  const handleDownloadClick = (data) => {
    return (event) => {
      event.preventDefault()
      handleDownload({
        key: data.download_btn.name,
        id: data[data.download_btn.name],
        filters: selectedFilter.extra,
        de: selectedFilter.dateEnd,
        dee: selectedFilter.dateEnd2,
        ds: selectedFilter.dateStart,
        dss: selectedFilter.dateStart2,
        title: data.tree
      })
    }
  }

  const goFavoriteAction = (favorite, productId) => {
    return (evt) => {
      if (favoris[productId] !== undefined) {
        setFavoris(!favoris[productId])
      }
      favoris[productId] = favorite
      favoriteAction(favorite, productId)
    }
  }

  if (value && value.type === "icon") {
    return (
      <Table.Cell className={className} style={style}>
        {value.value === "success" && <CheckCircleIcon color='primary' />}
        {value.value === "error" && <WarningIcon color='error' />}
      </Table.Cell>
    )
  }

  if (value && value.type === "health_check_email_subscription") {
    return (
      <Table.Cell className={className} style={style}>
        <HealthCheckEmailSubscriptionCheckbox defaultChecked={!!value.value} row={row} />
      </Table.Cell>
    )
  }

  if (name === 'fav') {
    return (
      <Table.Cell className={className} style={style}>
        <Checkbox
          className='grid-checkbox'
          defaultChecked={row.fav}
          onChange={goFavoriteAction(!row.fav, row.spid)}
          checkedIcon={<Star style={{ fill: yellowA700 }} />}
          icon={<StarBorder />}
        />
      </Table.Cell>
    )
  }

  if (name === 'product') {
    let productLink = ""
    if (row.spid) {
      productLink = '/ean/' + row.spid;
    }
    else if (row.product && row.product.link) {
      productLink = row.product.link;
    }
    const productValue = (row.product && Object.hasOwn(row.product,"value")) ? row.product.value : row.product;
    return (
      <Table.Cell className={`${className} product-cell`} style={style}>
        <Link to={productLink} className='link-style' target='_blank' rel="noopener noreferrer">
          <Chip
            style={{ display: 'inline-flex', backgroundColor: 'transparent' }}
            avatar={<Avatar src={row.image_url || (row.product && row.product.logo)} />}
            label={<Tooltip title={productValue}><span>{productValue}</span></Tooltip>}
          />
        </Link>
      </Table.Cell>
    )
  }
  if (name === 'campaign' && row.campaign && row.campaign.link && typeof row.campaign.link === 'string') {
    return (
      <Table.Cell className={`${className} product-cell`} style={style}>
        <Link to={row.campaign.link} className='link-style' target='_blank' rel="noopener noreferrer">
          <Chip
            style={{ display: 'inline-flex', backgroundColor: 'transparent' }}
            label={<Tooltip title={row.campaign.value}><span>{row.campaign.value}</span></Tooltip>}
          />
        </Link>
      </Table.Cell>
    )
  }
  return (
    <CellContent
      className={className}
      style={style}
      content={value}
      toogleModal={CelltoogleRowDetailModal}
      handleDownload={handleDownloadClick(row)}
    />
  )
}

const mapStateToProps = store => ({
  selectedFilter: store.app.selectedFilter
})

const mapDispatchToProps = dispatch => ({
  favoriteAction: (favorite, productId) => dispatch(actions.app.favoriteAction(favorite, productId)),
  CelltoogleRowDetailModal: (data) => dispatch(actions.app.toogleRowDetailModal(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RenderCell)
