import React, { useState, useContext, MouseEvent } from "react";
import { Button, Box, Tooltip, IconButton,Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { ChatContext } from "./ChatContext";
import { groupDates } from "./utils";
import { Session } from "./types";
import SessionActions from "./SessionActions";
import { RenameSessionPost, ShareSession, DeleteSession } from "./api";

interface User {
  id: number;
  name: string;
  image: string;
}

const SessionSelector: React.FC = () => {
  const {
    sessions,
    setCurrentSession,
    setSessions,
    currentSession,
    sessionLoading,
    refreshSessions,
  } = useContext(ChatContext);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [openSession, setOpenSession] = useState<null | string>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [renameOpen, setRenameOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [newSessionName, setNewSessionName] = useState("");


  const users: User[] = [
    { id: 1, name: "Alice Johnson", image: "https://via.placeholder.com/40" },
    { id: 2, name: "Bob Smith", image: "https://via.placeholder.com/40" },
    { id: 3, name: "Charlie Davis", image: "https://via.placeholder.com/40" },
  ];

  const handleMenuOpen = (sessionId: string, event: MouseEvent<HTMLElement>) => {
    setOpenSession(sessionId);
    console.log({sessionId})
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openRenameModal = () => {
    setRenameOpen(true);
    handleMenuClose();
    setNewSessionName("");
  };

  const openShareModal = () => {
    setShareOpen(true);
    handleMenuClose();
  };

  const cancelRenameModal = () => setRenameOpen(false);
  const closeShareModal = () => setShareOpen(false);

  const renameSession = async () => {
    console.log("Renaming session", openSession, newSessionName);
    if (!openSession || !newSessionName) return;
    setRenameOpen(false);
    await RenameSessionPost(openSession, newSessionName);
    handleMenuClose();
    setOpenSession(null);
    refreshSessions();
  };



  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const groupedSessions = groupDates(sessions);

  const sessionsElement = (sessions: any) => {
    return sessions.map((session: Session) => {
      const isActive =
        currentSession && session.session_id === currentSession.session_id;
      return (
        <Box
          key={session.session_id}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          className={`hover:bg-gray-300 ${isActive ? "bg-gray-300" : ""}`}
        >
          <Button
            key={session.session_id}
            onClick={() => setCurrentSession(session)}
            className={`text-sm	text-gray-600 rounded cursor-pointer hover:bg-gray-300 p-2 cursor-pointer normal-case	text-left	${isActive ? "bg-gray-300" : ""}`}
          >
            {session.title}
          </Button>
          <SessionActions
            sessionId={session.session_id}
            isOpen={openSession === session.session_id && anchorEl !== null}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            onRename={openRenameModal}
            onShare={openShareModal}
            refreshSessions={refreshSessions}
          />
        </Box>
      );
    });
  };

  const startEmptySession = () => {
    setMessageLoading(false);
    const emptySession = {
      session_id: "new",
      title: "New Session",
      created: new Date(),
    };
    setSessions((prevSessions: Session[]) => [emptySession, ...prevSessions]);
    setCurrentSession(emptySession);
  };

  return (
    <div
      className={`${
        sidebarOpen ? "w-1/4" : "w-0"
      } transition-all fixed lg:static lg:translate-x-0 text-gray-200 p-4 max-h-svh	overflow-y-auto flex-col flex-grow`}
    >
      <div className="absolute top-4 right-0 z-10">
        <IconButton onClick={toggleSidebar} classes="bg-gray-600">
          {sidebarOpen ? (
            <KeyboardDoubleArrowLeftIcon />
          ) : (
            <Tooltip title="New chat">
              <KeyboardDoubleArrowRightIcon />
              </Tooltip>
          )}
        </IconButton>
        {!sidebarOpen ? null : (
          <IconButton onClick={startEmptySession} classes="bg-gray-600">
            <AddBoxIcon />
          </IconButton>
        )}
      </div>
      <div className={`${sidebarOpen ? "" : "hidden"} space-y-4`}>
        <div className="space-y-2">
          {sessionLoading ? (
            <div key="loading" className="flex justify-center">
              <CircularProgress />
            </div>
          ) : sessions.length === 0 ? (
            <div key="thisWeek">
              <h2 className="text-sm text-gray-500">No sessions</h2>
            </div>
          ) : null}
          {groupedSessions.thisWeek.length > 0 ? (
            <div key="thisWeek">
              <h2 className="text-sm text-gray-500">This week</h2>
              <div className="space-y-2">
                {sessionsElement(groupedSessions.thisWeek)}
              </div>
            </div>
          ) : null}
          {groupedSessions.lastWeek.length > 0 ? (
            <div key="lastWeek">
              <h2 className="text-sm text-gray-500">Last week</h2>
              <div className="space-y-2">
                {sessionsElement(groupedSessions.lastWeek)}
              </div>
            </div>
          ) : null}
          {groupedSessions.lastMonth.length > 0 ? (
            <div key="older">
              <h2 className="text-sm text-gray-500">Last Month</h2>
              <div className="space-y-2">
                {sessionsElement(groupedSessions.lastMonth)}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* Rename Modal */}
      <Dialog open={renameOpen} onClose={cancelRenameModal}>
        <DialogTitle>Rename Session</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Session Name"
            fullWidth
            onChange={(e) => setNewSessionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelRenameModal}>Cancel</Button>
          <Button onClick={() => renameSession()} color="primary">
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      {/* Share Modal */}
      <Dialog open={shareOpen} onClose={closeShareModal}>
        <DialogTitle>Share Session</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={users}
            fullWidth
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => setSelectedUser(value)}
            renderOption={(props, option) => (
              <Box component="li" {...props} display="flex" alignItems="center">
                <img
                  src={option.image}
                  alt={option.name}
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: "50%",
                    marginRight: 10,
                  }}
                />
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Select User" variant="outlined" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeShareModal}>Cancel</Button>
          <Button onClick={() => console.log("Shared with", selectedUser)} color="primary">
            Share
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SessionSelector;
