import * as React from 'react';
import { DataGridPro,GridPinnedColumns, GridRowId,GridRow, GridRenderRowProps,GridRowProps,GridRowParams,DataGridProProps,GridGroupingColDefOverride, useGridApiRef,GridToolbar } from '@mui/x-data-grid-pro';
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import IconButton from '@mui/material/IconButton'
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import InsertLink from '@mui/icons-material/InsertLink'
import { Link } from 'react-router-dom';
import Search from '@mui/icons-material/Search'
import TrendingDown from '@mui/icons-material/TrendingDown'
import TrendingFlat from '@mui/icons-material/TrendingFlat'
import TrendingUp from '@mui/icons-material/TrendingUp'
import { Button, Tooltip } from "@mui/material";
import { Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridFilterOperator,
  DEFAULT_GRID_COL_TYPE_KEY,
  getGridDefaultColumnTypes,
  GridToolbarDensitySelector, GridColDef, GridRenderCellParams
} from '@mui/x-data-grid';
import { betweenOperator } from './filters';
import { DgGridTreeDataGroupingCell } from './DgGridTreeDataGroupingCell';
import "./TableGridStyles.scss";

const defaultColumnTypes = getGridDefaultColumnTypes();

const defaultFilterOperators = defaultColumnTypes[DEFAULT_GRID_COL_TYPE_KEY].filterOperators;
const XDataGridv2 = (props: { table: any; }) => {
  const { table } = props;
  if (!table) return null;
  
  const renderCell = (params: GridRenderCellParams) => {
    const row = params.row;
    const field = params.field;
    const style = {};
    let content = params.value
    try {
      content = JSON.parse(params.value)
    }
    catch (e) {

    }
    const [imageModal, setImageModal] = React.useState({
      isOpen: false,
      activeImageIndex: 0
    })

    //const { handleDownload } = React.useContext(WebsocketContext)
    //if (hidden) return null;

    if (content === undefined || content === null ) return <div style={style} className='evo-cell'></div>
    
    if (content.logo) {
      return (
        <div className="evo-cell">
          {content.link !== undefined ?
            <Link to={content.link} className='link-style' target='_blank' rel="noopener noreferrer">
              <Chip
                className='dg-style'
                style={{ cursor: 'pointer' }}
                avatar={<Avatar src={content.logo} />}
                label={<Tooltip title={content.value}><div>{content.value}</div></Tooltip>}
              />
            </Link>
            :
            <Chip
              className='dg-style'
              avatar={<Avatar src={content.logo} />}
              label={<Tooltip title={content.value}><div>{content.value}</div></Tooltip>}
            />}
        </div>
      )
    }

    if (content && content.value && content.link && typeof content.link === 'string') {
      return (
        <div className="evo-cell" style={style}>
          <Link to={content.link} className='link-style' target='_blank' rel="noopener noreferrer">
            <Chip
              style={{ display: 'inline-flex' }}
              label={<Tooltip title={content.value}><span>{content.value}</span></Tooltip>}
            />
          </Link>
        </div>
      )
    }

    if (content.sticker) {
      return (
        <div className="evo-cell" style={style} >
          <Chip
            style={{ display: 'inline-flex', borderRadius: '6px', marginTop: '6px', fontSize: '14px', fontWeight: 'bold', color: '#fff', height: '26px' }}
            className={content.color}
            label={content.sticker}
          />
        </div>
      )
    }

    if (content.image_link && content.image_link.length > 0) {
      return (
        <div className="evo-cell" style={style} >
          <button
            style={{ background: "transparent", border: "none", cursor: "pointer" }}
            onClick={(e) => {e.stopPropagation(); setImageModal({ activeImageIndex: 0, isOpen: true });}}
            rel="noopener noreferrer"
            className='link-style evo-cell__image-link-cell-button'
          >
            {content.image_link.length > 1 && (
              <div className="evo-cell__images-count">
                {content.image_link.length}
              </div>
            )}
            <ImageSearchIcon />
          </button>
          <Modal
            open={imageModal.isOpen}
            onClose={() => setImageModal({ isOpen: false, activeImageIndex: 0 })}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="image-modal"
          >
            <div
              className="image-modal__container"
            >
              <div className="image-modal__container__body">
                <div id="modal-modal-title" className="image-modal__container__body__title">
                  <div>
                    {content.image_link.length > 1 && <div>{imageModal.activeImageIndex + 1}/{content.image_link.length}</div>}
                    <center>{row && `${row.format} - ${row.period}`}</center>
                  </div>
                  <div onClick={() => setImageModal({ isOpen: false, activeImageIndex: 0 })}>
                    <CloseIcon />
                  </div>
                </div>
                <div id="modal-modal-description" className="image-modal__container__body__content" >
                  {content.image_link.length > 1 && <Button variant="text" onClick={() => setImageModal(v => ({ ...v, activeImageIndex: v.activeImageIndex - 1 < 0 ? content.image_link.length - 1 : v.activeImageIndex - 1 }))} >
                    <ArrowBackIosIcon />
                  </Button>}
                  <img style={{ maxWidth: "80%" }} src={content.image_link[imageModal.activeImageIndex]} alt="" />
                  {content.image_link.length > 1 && <Button variant="text" onClick={() => setImageModal(v => ({ ...v, activeImageIndex: v.activeImageIndex + 1 > content.image_link.length - 1 ? 0 : v.activeImageIndex + 1 }))} >
                    <ArrowForwardIosIcon />
                  </Button>}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )
    }

    if (content.download) {
      return (
        <div className="evo-cell" style={style} >
          <IconButton size='medium'
          //onClick={handleDownload}
          >
            <CloudDownloadIcon fontSize="medium" />
          </IconButton>
        </div>
      )
    }
    if (content.value2_grey && content.value2_grey === true) {
      return (
        <div className="evo-cell" >
          <div>
            {
              content['data-modal'] ? (
                <span
                  className='data-modal-cell count-cell'
                //onClick={() => toogleModal(content['data-modal'])}
                >
                  {content.value} <span>{'/' + content.value2}</span>
                  <Search style={{ fontSize: 18 }} />
                </span>
              ) : <div className='count-cell'>{content.value} <span>{'/' + content.value2}</span></div>
            }

            {content.value_b !== undefined &&
              <div className='sub' >
                <span style={{ color: content.color }}>{content.value_b} {content.value2_b !== undefined && `/${content.value2_b}`}</span>
                {content.evo === 'asc' ? <TrendingUp style={{ color: content.color, fontSize: '12px' }} />
                  : content.evo === 'desc' ? <TrendingDown style={{ color: content.color, fontSize: '12px' }} />
                    : <TrendingFlat style={{ color: content.color, fontSize: '12px' }} />}
              </div>
            }
          </div>
        </div>
      )
    }
    if (Array.isArray(content.link)) {
      const links = content.link.filter((item: null) => item !== null)
      return (
        <div className="evo-cell" >
          {links.map((jrow: string | undefined, jindex: React.Key | null | undefined) => (
            <a href={jrow} key={jindex} rel="noopener noreferrer" target='_blank' className='link-style icon'>
              <InsertLink style={{ fill: 'rgb(117, 117, 117)' }} />
            </a>
          ))
          }
        </div>
      )
    }

    if (Array.isArray(content)) {
      return (
        <div className='cell-chip-conteiner' >
          {content.map((item, index) => (
            item && <a key={index} href={item.link} target='_blank' rel='noopener noreferrer' className='link-style'>
              <Chip
                style={{ display: 'inline-flex', marginRight: '3px', marginBottom: '5px', cursor: 'pointer', fontSize: '11px', lineHeight: '22px', height: '22px' }}
                className={item.color}
                label={item.label}
              />
            </a>
          ))}
        </div>
      )
    }
    return (
      <div className="evo-cell">
        <div>
          {
            content['data-modal'] ? (
              <span
                className='data-modal-cell'
              //onClick={() => toogleModal(content['data-modal'])}
              >
                {content.value?.toLocaleString()}{content.value_unit !== undefined && `${content.value_unit}`} {content.value2 !== undefined && `/${content.value2}`}{content.value2_unit !== undefined && `${content.value2_unit}`}
                <Search style={{ fontSize: 18 }} />
              </span>
            ) : <Tooltip title={content.value?.toLocaleString()}><div style={{ color: content.color }}>{content.value?.toLocaleString()}{content.value_unit !== undefined && `${content.value_unit}`} {content.value2 !== undefined && `/${content.value2}`}{content.value2_unit !== undefined && `${content.value2_unit}`}</div></Tooltip>
          }

          {content.value_b !== undefined &&
            <div className='sub' >
              <span style={{ color: content.color }}>{content.value_b}{content.value_b_unit !== undefined && `${content.value_b_unit}`} {content.value2_b !== undefined && `/${content.value2_b}`}{content.value2_b_unit !== undefined && `${content.value2_b_unit}`}</span>
              {content.evo === 'asc' ? <TrendingUp style={{ color: content.color, fontSize: '12px' }} />
                : content.evo === 'desc' ? <TrendingDown style={{ color: content.color, fontSize: '12px' }} />
                  : <TrendingFlat style={{ color: content.color, fontSize: '12px' }} />}
            </div>
          }

          {typeof content === 'string' && <Tooltip title={content}><div className="evo-cell__long-text" >{content}</div></Tooltip>}
          {typeof content === 'number' && content.toLocaleString()}
        </div>
      </div>
    )
  }
  function CustomToolbar() {
    // removing export button
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <Box sx={{ flexGrow: 1 }} />
      </GridToolbarContainer>
    );
  }

  const colGroupingValueGetter = (value: any) => {
    return JSON.stringify(value)
  };

  const colComparator = (value1: any, value2: any) => {
    if (value1 === null || value2 === null) return 0;
    if (value1.value != undefined && value2.value != undefined)
      return Number(value1.value) - Number(value2.value);
    if (typeof value1 === 'number' && typeof value2 === 'number')
      return Number(value1) - Number(value2);
    if (typeof value1 === 'string' && typeof value2 === 'string')
      return value1.localeCompare(value2)
    return 0

  }

  const numericObjectWrapOperator = (operator: GridFilterOperator) => {
    const getApplyFilterFn: GridFilterOperator['getApplyFilterFn'] = (
      filterItem,
      column,
    ) => {
      const innerFilterFn = operator.getApplyFilterFn(filterItem, column);
      if (!innerFilterFn) {
        return innerFilterFn;
      }
      return (value, row, col, apiRef) => {
        return innerFilterFn(value ? Number(value.value) : Number(0), row, col, apiRef);
      };
    };

    return {
      ...operator,
      getApplyFilterFn,
    };
  };

  const numericWrapOperator = (operator: GridFilterOperator) => {
    const getApplyFilterFn: GridFilterOperator['getApplyFilterFn'] = (
      filterItem,
      column,
    ) => {
      const innerFilterFn = operator.getApplyFilterFn(filterItem, column);
      if (!innerFilterFn) {
        return innerFilterFn;
      }
      return (value, row, col, apiRef) => {
        return innerFilterFn(value ? Number(value) : Number(0), row, col, apiRef);
      };
    };

    return {
      ...operator,
      getApplyFilterFn,
    };
  };

  const getFilterOperators = (colName: string, column_type: any) => {
    if (column_type[colName] === "numeric_object") {
      return [betweenOperator, ...defaultColumnTypes["number"].filterOperators!.map((operator) => numericObjectWrapOperator(operator))];
    }
    if (column_type[colName] === "numeric") {
      return [betweenOperator, ...defaultColumnTypes["number"].filterOperators!.map((operator) => numericWrapOperator(operator))]
    }
    if (column_type[colName] === "date") {
      return defaultColumnTypes["date"].filterOperators!;
    }
    if (column_type[colName] === "str") {
      return defaultColumnTypes["string"].filterOperators!;
    }
    if (column_type[colName] === "boolean") {
      return defaultColumnTypes["boolean"].filterOperators!;
    }
    return defaultFilterOperators
  }

  const getCellValue = (colName: string, column_type: any) => {
    if (column_type[colName] === "numeric_object") {
      return (params: GridRowParams) => {
        return params ? Number(params.value) : null;
      };
    }
    if (column_type[colName] === "date") {
      return (params: GridRowParams) => {
        return params ? new Date(params) : null;
      };
    }
    if (column_type[colName] === "str") {
      return (params: GridRowParams) => {
        return params ? String(params) : null;
      };
    }
    if (column_type[colName] === "boolean") {
      return (params: GridRowParams) => {
        return params ? Boolean(params) : null;
      };
    }
  }
  const getCols = (cols: any, column_type: any, hiddenCols: string[]) => {
    return cols.filter((col: any) => !hiddenCols.includes(col.name)).map((col: any) => {
      return {
        field: col.name,
        headerName: col.title,
        renderCell,
        groupingValueGetter: colGroupingValueGetter,
        sortComparator: colComparator,
        filterOperators: getFilterOperators(col.name, column_type),
        //valueGetter: getCellValue(col.name, column_type),

      };
    })
  };
  const treeCol = "tree"
  const getRows = (rows: any) => {
    let finalData = []
    rows.forEach(row => {
      if (row[treeCol].some((el) => el === null)) return;
      const treeColValue = row[treeCol].map(el => JSON.stringify(el))
      const fixedRow = { ...row, tree: treeColValue };
      finalData.push(fixedRow)
    });
    return finalData;
  };

  const rows = React.useMemo<GridColDef[]>(
    () => getRows(table.rows),
    [table.rows],
  );

  const columns = React.useMemo<GridColDef[]>(
    () => getCols(table.columns, table.column_type, [treeCol]),
    [table.columns, table.column_type],
  );
  const groupingColDef: DataGridProProps['groupingColDef'] = {
      headerName: table.group,
      renderCell: (params) => <DgGridTreeDataGroupingCell {...params} cellRender={renderCell} />,
      groupingValueGetter: colGroupingValueGetter,
      sortComparator: colComparator,
      hideDescendantCount: true,
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      pinnable: true,
      //flex: 1,
  };

  function convertCSSStringToStyleObject(cssString: string): Record<string, string> {
    // Split the CSS string into an array of key-value pairs
    const styleArray = cssString.trim().split(';').map(pair => pair.trim().split(': '));
  
    // Convert the array of key-value pairs into an object
    const styleObject = styleArray.reduce<Record<string, string>>((acc, [key, value]) => {
      // Convert the CSS property name to camelCase
      const camelCaseKey = key.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
      acc[camelCaseKey] = value;
      return acc;
    }, {});
  
    return styleObject;
  }
  
  const CustomRow = (params: GridRowProps) => {
    const { row } = params;
    const rowStyles = row._style ? convertCSSStringToStyleObject(row._style) : {};
    return row._style ? <div style={rowStyles}><GridRow {...params} /> </div>: <GridRow {...params} />;
  };
  const pinnedColumns = {left: ['__tree_data_group__']};
  return (
    <div style={{ height: "100%", width: '100%',display: 'flex', flexDirection: 'column' }}>
      <DataGridPro
        slots={{
          toolbar: CustomToolbar,
          row: CustomRow,
        }}
        initialState={{ pinnedColumns: pinnedColumns }}
        treeData
        getTreeDataPath={(row) => row[treeCol]}
        rows={rows}
        columns={columns}
        sx={{ flex: 1 }}
        groupingColDef={groupingColDef}
        localeText={{
          'filterOperator!=': '≠'
        }}
      />
    </div>
  );
}

export default XDataGridv2;